(() => {
  const filterNullValuesFromObject = object => Object.fromEntries(Object.entries(object).filter(([_, v]) => v != null && v !== '' && typeof v !== 'undefined'));

  const mapProperties = object => ({
    company_id: object.demandbase_sid.toString(),
    company_name: object.company_name,
    company_domain: object.web_site,
    company_industry: object.industry,
    company_number_of_employees: object.employee_count,
    company_annual_revenue: object.annual_sales,
    company_country: object.country
  });

  const processIdentify = object => {
    const companyObject = filterNullValuesFromObject(mapProperties(object));

    window.HockeyStack.addSharedProperty({
      key: 'company_id',
      value: companyObject.company_id,
      properties: {
        ...companyObject,
        is_crm_object: false,
        is_anonymous_identified: true
      }
    });
  }

  const getData = () => {
    if (!(timeout > 10_000 || (window.Demandbase && window.Demandbase.Segments.CompanyProfile))) {
      timeout += 50;
      setTimeout(getData, 50);
      return;
    }

    try {
      const companyObject = window.Demandbase.Segments.CompanyProfile;

      if (companyObject.web_site) {
        if (window.HockeyStack) return processIdentify(companyObject);

        if (!Array.isArray(window.hockeystackQueue)) {
          window.hockeystackQueue = [];
        }
        window.hockeystackQueue.push(() => processIdentify(companyObject));
      }
    } catch (error) {}
  };

  let timeout = 0;
  getData();
})();
